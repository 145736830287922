import api from 'api';
import Input from 'components/common/input';
import NProgressStyle from 'components/common/nprogress';
import validationMessage from 'config/validation-messages';
import { Formik } from 'formik';
import NProgress from 'nprogress';
import React, { useState } from 'react';
import phoneRegex from 'utils/phone-regex';
import * as yup from 'yup';
import { Form, PleaseNote } from './styles';
import { Button } from 'lib/Button';
import styled from 'styled-components';
import theme from 'config/theme';
import { Checkbox } from 'lib/Checkbox';
import routes from 'config/routes';
import { notifyFailure, notifySuccess } from 'lib/Notifications';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import MobileNumberInput from 'lib/MobileNumberInput';
import countryCodes from 'constants/countryCodes';

const { colors, fontWeights } = theme;

const MIN_MESSAGE_LENGTH = 10;
const MAX_MESSAGE_LENGTH = 750;

const contactSchema = yup.object().shape({
	name: yup.string().required(validationMessage.required),
	surname: yup.string().required(validationMessage.required),
	email: yup
		.string()
		.email(validationMessage.email)
		.required(validationMessage.required),
	mobile: yup.string().matches(phoneRegex, 'Invalid number'),
	message: yup
		.string()
		.min(MIN_MESSAGE_LENGTH, validationMessage.short)
		.max(MAX_MESSAGE_LENGTH, validationMessage.long)
		.required(validationMessage.required),
});

const ContactForm = ({ disclaimer, isFullWidth = false, reason }) => {
	const [submitted, setSubmitted] = useState(false);
	const [failed, setFailed] = useState(false);
	const [privacyConsentChecked, setPrivacyConsentChecked] = useState(false);
	const [showPrivacyAndTermsError, setShowPrivacyAndTermsError] = useState(false);
	const [region, setRegion] = useState('ZA');
	const [mobileNumber, setMobileNumber] = useState('');

	const handleTermsCheckboxClick = () => {
		setPrivacyConsentChecked(value => !value);
	};

	const disclaimerSection = disclaimer ? (
		<PleaseNote>
			<strong>Please note:</strong> DigsConnect <strong>DOES NOT</strong> own or operate any student
			accommodation.
		</PleaseNote>
	) : (
		''
	);

	return (
		<div style={{ width: isFullWidth ? '100%' : 'auto' }}>
			<NProgressStyle />
			{disclaimerSection}
			<Formik
				initialValues={{
					name: '',
					surname: '',
					email: '',
					mobile: '',
					message: '',
					reason: reason,
				}}
				validationSchema={contactSchema}
				onSubmit={async (values, { setSubmitting }) => {
					if (!privacyConsentChecked) {
						setShowPrivacyAndTermsError(true);
						setSubmitting(false);
						return;
					}

					if (featureFlagContainer.isEnabled(FeatureFlag.SingleMobileNumberInput)) {
						if (!region || !mobileNumber) {
							setSubmitting(false);
							return;
						}
					}

					NProgress.start();

					try {
						// NOTE: values.mobile not used by API
						const res = await api.contactUs.contactUsV2(
							values.name,
							values.surname,
							values.email,
							featureFlagContainer.isEnabled(FeatureFlag.SingleMobileNumberInput)
								? `${countryCodes.find(item => item.code === region).dial_code}${mobileNumber}`
								: values.mobile,
							values.reason,
							values.message,
						);

						if (res.status === 201) {
							notifySuccess('Contact us successfully submitted');
							setSubmitted(true);
							setFailed(false);
						} else {
							notifyFailure("You've exceeded your limit on contact us requests sent.");
							setFailed(true);
						}

						NProgress.done();
						setSubmitting(false);
					} catch (error) {
						console.error(error);
						setFailed(true);
						notifyFailure("You've exceeded your limit on contact us requests sent.");
						NProgress.done();
						setSubmitting(false);
					}
				}}
			>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
					<Form onSubmit={handleSubmit} isFullWidth={isFullWidth}>
						<Input
							name="name"
							placeholder="Name"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.name}
							error={errors.name && touched.name && errors.name}
						/>

						<Input
							type="text"
							name="surname"
							placeholder="Surname"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.surname}
							error={errors.surname && touched.surname && errors.surname}
						/>

						<Input
							type="email"
							name="email"
							placeholder="Email"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.email}
							error={errors.email && touched.email && errors.email}
						/>

						{featureFlagContainer.isEnabled(FeatureFlag.SingleMobileNumberInput) ? (
							<MobileNumberInput
								country={region}
								mobileNumber={mobileNumber}
								onCountrySelect={setRegion}
								onMobileNumberChange={setMobileNumber}
								noBorder
							/>
						) : (
							<Input
								type="text"
								name="mobile"
								placeholder="Mobile"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.mobile}
								error={errors.mobile && touched.mobile && errors.mobile}
							/>
						)}

						<Input.Container>
							<TextArea
								as="textarea"
								name="message"
								placeholder="Message"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.message}
							/>
							<Input.Error>{errors.message && touched.message && errors.message}</Input.Error>
						</Input.Container>

						<Checkbox checked={privacyConsentChecked} onClick={handleTermsCheckboxClick}>
							<CheckboxText isError={!privacyConsentChecked && showPrivacyAndTermsError}>
								I agree to the{' '}
								<CheckboxLink href={routes.terms_and_conditions} target="_blank">
									terms and conditions
								</CheckboxLink>{' '}
								and{' '}
								<CheckboxLink href={routes.privacy} target="_blank">
									privacy policy
								</CheckboxLink>
							</CheckboxText>
						</Checkbox>

						<Button type={'submit'} disabled={isSubmitting || submitted}>
							{submitted ? 'Thank you!' : failed ? 'Oops! Try again' : 'Send'}
						</Button>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default ContactForm;

const CheckboxText = styled.p`
	line-height: 1.3;
	margin-bottom: 0;
	color: ${({ isError }) => (isError ? colors.red : colors.grey60)};
`;

const CheckboxLink = styled.a`
	cursor: pointer;
	color: ${colors.darkTurquoise};
	font-weight: ${fontWeights.bold};
`;

const TextArea = styled(Input.TextArea)`
	margin-bottom: 0;
`;
