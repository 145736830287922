import HeroMobileImg from 'assets/images/contact-us/hero-mobile.svg';
import HeroImg from 'assets/images/contact-us/hero.svg';
import Dino from 'assets/images/contact-us/contact-dino.svg';
import Container from 'components/common/container';
import Footer from 'components/common/footer';
import Header from 'components/common/header';
import Hero from 'components/common/hero';
import Main from 'components/common/main';
import ContactForm from 'components/common/contact-form';
import React from 'react';
import Helmet from 'react-helmet';
import {
	ContentContainer,
	HeroContainer,
	Title,
	SubTitle,
	Img,
	TitleContainer,
	SocialContainer,
} from './styles';
import AcademicPartners from 'components/common/academic-partners';
import theme from 'config/theme';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import { PageMeta } from 'constants/page_meta';
import SocialLinks from 'components/common/social-links';
import ContactUsForm from './ContactUsForm';

const { colors } = theme;
const meta = PageMeta.getStatic('contact_us');
const ContactUs = () => (
	<Main>
		<Helmet>
			<title>{meta.title}</title>
			<meta name="description" content={meta.desc} />
		</Helmet>
		<Header />
		<ContentContainer>
			<ContactUsForm />
			<HeroContainer bgColor={colors.darkBlue}>
				<TitleContainer>
					<Title>We'd love to hear from you</Title>
					<SubTitle>Check out our social media pages</SubTitle>
					<SocialContainer>
						<SocialLinks color={colors.white} margin={'16px'} />
					</SocialContainer>
				</TitleContainer>
				<Img mobile={HeroMobileImg} source={Dino} />
			</HeroContainer>
		</ContentContainer>
		<Footer />
	</Main>
);

export default ContactUs;
