import { flexBetween } from 'config/mixins';
import theme from 'config/theme';
import styled from 'styled-components';

const { colors, fontSizes, fontWeights, lineHeight, fonts, letterSpacings } = theme;

export const Container = styled.section`
	${flexBetween};
	height: 440px;
	padding: 20px 14vw 0;
	position: relative;
	background-color: ${props => props.bgColor};
	background-position: right;
	background-size: contain;
	margin-bottom: 80px;

	@media (max-width: 1100px) {
		flex-direction: column;
		min-height: 20vh;
		padding: 30px;
	}

	@media (max-width: 900px) {
		margin-bottom: 0;
	}

	@media (max-width: 500px) {
		height: 220px;
	}
`;

export const Title = styled.h2`
	color: ${colors.white};
	font-size: ${fontSizes.header};
	font-weight: ${fontWeights.bold};
	line-height: ${lineHeight.snug};
	font-family: ${fonts.Gilroy};
	letter-spacing: ${letterSpacings.tight};
	width: 640px;
	min-width: 300px;
	z-index: 1;

	@media (max-width: 1280px) {
		font-size: ${fontSizes.xxlarge};
	}

	@media (max-width: 500px) {
		font-size: ${fontSizes.xlarge};
		width: 300px;
		padding: 0;
		margin: 0;
		left: 0;
	}

	@media (max-width: 350px) {
		font-size: ${fontSizes.xlarge};
		width: 300px;
	}
`;

export const Img = styled.div`
	height: 100%;
	background-image: url(${props => props.source});
	width: 100%;
	background-size: contain;
	margin-right: -80px;
	background-position: right bottom;

	@media (max-width: 1100px) {
		position: absolute;
		top: 0%;
		bottom: 0;
		left: 20%;
		margin: 0;
		width: 80%;
		${props =>
			props.mobile && {
				width: '80%',
				marginLeft: '-40px',
			}}
	}
`;
