import Resize from '@tiaanduplessis/react-resize';
import theme from 'config/theme';
import React from 'react';

import { Container, Img, Title } from './styles';

const { colors } = theme;

const Hero = ({ text, bgImg, bgImgMobile, color }) => (
	<Container bgColor={color}>
		<Title>{text}</Title>
		<Resize>
			{({ width }) => (
				<Img mobile={bgImgMobile} source={width < 900 ? bgImgMobile || bgImg : bgImg} />
			)}
		</Resize>
	</Container>
);

Hero.defaultProps = {
	text: '',
	bgImg: '',
	color: colors.darkBlue,
};

export default Hero;
