import theme from 'config/theme';
import styled from 'styled-components';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

const { colors, fontSizes, fontWeights, lineHeight, fonts, letterSpacings } = theme;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px;
	align-items: center;
	width: 50%;
	gap: 24px;
	background: ${colors.white};

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		width: 100%;
	}
`;

export const HeroContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh - 50px;
	width: 50%;
	padding: 50px 0px 0px 24px;
	position: relative;
	background-color: ${props => props.bgColor};
	background-position: right;
	background-size: contain;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		min-height: 20vh;
		height: 50%;
		width: 100%;
		align-items: center;
	}
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: row;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		flex-direction: column;
		overflow-y: scroll;
	}
`;
export const ItemContainer = styled.div`
	display: flex;
	flex-diretion: row;
	width: 100vw;
	align-items: flex-start;

	@media (max-width: 900px) {
		flex-direction: column;
	}
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
`;

export const SocialContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;

	@media (max-width: 650px) {
		align-items: flex-start;
	}
`;
export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	flex: 1;
	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		flex: none;
	}
`;
export const SubTitle = styled.div`
	font-weight: 700;
	font-size: 25px;
	line-height: 110%;
	color: ${colors.white};
`;

export const Title = styled.h2`
	color: ${colors.white};
	font-size: 48px;
	font-weight: ${fontWeights.bold};
	font-family: ${fonts.Gilroy};
	letter-spacing: ${letterSpacings.tight};
`;

export const Img = styled.div`
	height: 100%;
	background-image: url(${props => props.source});
	width: 100%;
	background-size: contain;
	background-position: right bottom;
	position: relative;
	right: 0;
	bottom: 0;
	height: 50vh;
	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		background-position: right bottom;
		position: relative;
		right: 0;
		bottom: 0;
		margin: 0;
		height: 20vh;
	}
`;
