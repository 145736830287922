import theme from 'config/theme';
import styled from 'styled-components';

const { fontSizes, fontWeights, colors, fonts } = theme;

export const Title = styled.h3`
	font-size: ${fontSizes.large};
	font-family: ${fonts.Gilroy};
	font-weight: ${fontWeights.bold};
	margin-bottom: 40px;
`;

export const Description = styled.p`
	font-weight: ${fontWeights.thin};
	font-family: ${fonts.Gilroy};
	color: ${colors.lightBlue};
	margin-bottom: 40px;
`;

export const PleaseNote = styled.p`
	font-weight: ${fontWeights.thin};
	font-family: ${fonts.Gilroy};
	color: ${colors.grey60};
	margin-bottom: 40px;
`;

export const Form = styled.form`
	width: ${({ isFullWidth }) => (!isFullWidth ? '60%;' : '100%')};
	min-width: 300px;
	margin: auto;
	${({ isFullWidth }) => (!isFullWidth ? 'margin-left: 0;' : '')}

	@media (max-width: 420px) {
		width: 100%;
		min-width: unset;
	}
`;
